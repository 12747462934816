const $ = (css, parent = document) => parent.querySelector(css);
const $$ = (css, parent = document) => parent.querySelectorAll(css);

const UIkit = window.UIkit

import icons from './uikit-icons';
UIkit.use(icons)


window.kmc = {};
window.kmc.widgets = {};

window.kmc.widgets.getLendingCriteriaWidget = async (model) => {

    const Vue = await import('vue')
    const widget = await import('./widgets/LendingCriteriaWidget.vue');
    return Vue.createApp(widget.default, model)
}


import('./uikit-iconslib').then((icons) => {
    icons.default()
})

setTimeout(() => {
    $$('a[href]').forEach(function (a) {
        if (a.href.indexOf('javascript') == 0) return

        try {
            let link = new URL(a.href);
            if (link.host != document.location.host) {
                a.setAttribute('target', '_blank')
                a.setAttribute('rel', 'noopener noreferrer')
            }
        } catch (e) {
            console.error(e)
        }
    })
}, 0)



function initOldPagesWrapper() {
    var frameContainer = document.querySelector('[data-kmc-old-page]');
    if (frameContainer) {
        frameContainer.classList.add('uk-width-1-1');

        var spinner = document.createElement('div');
        spinner.setAttribute('uk-spinner', 'ratio: 2');

        frameContainer.appendChild(spinner);

        var frame = document.createElement('iframe');

        frame.setAttribute('allowtransparency', 'true');

        frame.src = frameContainer.dataset.kmcOldPage;
        frame.classList.add(['uk-height-1-1', 'uk-width-1-1']);
        frame.style.height = '0px';

        frameContainer.appendChild(frame);
        addEventListener('message', function (e) {
            if (e.data && e.data.hasOwnProperty('form1')) {
                spinner.remove()
                frame.style.height = e.data.form1.height + 'px';
                frame.style.backgroundColor = 'transparent';
            }
        });
    }
}

initOldPagesWrapper();



var equaliseBodyHeight = function () {
    document.querySelectorAll('.uk-grid.equaliseBodyHeight').forEach(function (grid) {

        let maxHeight = 0;

        grid.querySelectorAll('.uk-card-body').forEach(element => {
            const height = element.getBoundingClientRect().height;

            // update max height if current element's height is greater than current max height
            if (height > maxHeight) {
                maxHeight = height;
            }
        });


        grid.querySelectorAll('.uk-card-body').forEach(element => {
            element.style.minHeight = maxHeight + 'px';
        });

    });
}
equaliseBodyHeight();

addEventListener("resize", (event) => {
    equaliseBodyHeight();
});
